.progress {
    width: 67.2px;
    height: 67.2px;
    border: 16.8px solid #dbdcef;
    border-radius: 50%;
    position: relative;
    transform: rotate(45deg);
}

.progress::before {
    content: "";
    position: absolute;
    inset: -16.8px;
    border-radius: 50%;
    border: 16.8px solid #474bff;
    animation: progress-1tucza 2s infinite linear;
}

@keyframes progress-1tucza {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
    }
}